import '../scss/style.scss';
// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'
// import 'leaflet/dist/leaflet.css';
// import 'leaflet/dist/leaflet.js';
// import 'leaflet.markercluster/dist/MarkerCluster.css';
// import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
// import 'leaflet.markercluster/dist/leaflet.markercluster.js';

import * as articles from './articles.json'
import * as images from './images.json'
import * as papers from './academic.json'

let lastz = 0;
let lastindex = 0;
let stackDepth = 3
let laststackdir = 1;

function reserachOutputs() {
    // ok, so we have a div container, called researchoutputs
    // we want to display the research outputs in the academic.json file in this div
    // the function createReserachContainer should be used to create the divs for each research output
    // its going to be a carousel , ther are 17 items in total, 3 should be displayed at a time and the user can scroll through them using forward and back buttons
    // the buttons should be styled to look like arrows, and should be placed on either side of the carousel
    // the carousel should be 80% of the width of the container, and 100% of the height, and should have no special styling.
    // do not use horizontal scrolling to accomplish this, instead generate 3 divs, and display them in a row, and then change the divs when the user clicks the forward or back buttons
    var researchOutputs = document.getElementById('researchOutputs');
    var currentOutput = 0;
    var outputContainers = [];
    // create the output containers
    for (let i = 0; i < 16; i++) {
        outputContainers.push(createReserachContainer(papers[i]));
    }
    // create the carousel container
    var carousel = document.createElement('div');
    carousel.className = 'carousel';
    carousel.style.display = 'flex';
    carousel.style.width = '100%';
    carousel.style.height = '100%';
    carousel.style.gap = '20px';
    carousel.style.justifyContent = 'space-between';

    var outputContainer = document.createElement('div');
    outputContainer.style.display = 'flex';
    outputContainer.style.gap = '20px';
    outputContainer.style.height = '100%';

    // create the back button
    var backButton = document.createElement('div');
    backButton.innerHTML = '<';
    backButton.style.fontSize = '30px';
    backButton.style.cursor = 'pointer';
    backButton.style.marginLeft = '20px';
    backButton.onclick = function () {
        if (currentOutput > 0) {
            currentOutput -= 1;
            // clear the output container
            outputContainer.innerHTML = '';
            // starting with currentOutput, add the next 3 outputContainers to the outputContainer
            for (let i = 0; i < 3; i++) {
                // remove the last child of the carousel, and create a new one to add to the beginning
                outputContainer.appendChild(outputContainers[currentOutput + i]);
            }
        }
    }
    // create the forward button
    var forwardButton = document.createElement('div');
    forwardButton.innerHTML = '>';
    forwardButton.style.fontSize = '30px';
    forwardButton.style.cursor = 'pointer';
    forwardButton.style.marginRight = '20px';
    forwardButton.onclick = function () {
        if (currentOutput < outputContainers.length - 3) {
            currentOutput += 1;
            // clear the output container
            outputContainer.innerHTML = '';
            // starting with currentOutput, add the next 3 outputContainers to the outputContainer
            for (let i = 0; i < 3; i++) {
                // remove the last child of the carousel, and create a new one to add to the beginning
                outputContainer.appendChild(outputContainers[currentOutput + i]);
            }
        }
    }
    // add the back button to the carousel
    carousel.appendChild(backButton);
    // create a container to hold the output containers


    // add the output containers to the carousel
    for (let i = 0; i < 3; i++) {
        outputContainer.appendChild(outputContainers[i]);
    }
    carousel.appendChild(outputContainer);
    // add the forward button to the carousel
    carousel.appendChild(forwardButton);
    // add the carousel to the researchOutputs div
    researchOutputs.appendChild(carousel);


}

function createReserachContainer(output) {
    var researchOutput = document.createElement('div');
    researchOutput.className = 'researchOutput';
    // so inside this container, we want to first put the date, and the tite of the journal, this will be in a div, and spaced using flexbox
    var dateJournal = document.createElement('div');
    dateJournal.style.display = 'flex';
    dateJournal.style.justifyContent = 'flex-start';
    dateJournal.style.gap = '10px';
    var date = document.createElement('p');
    date.innerHTML = output.Date;


    var journal = document.createElement('p');
    journal.innerHTML = output.Journal;
    journal.style.fontWeight = 'bold';
    dateJournal.appendChild(journal);
    dateJournal.appendChild(date);
    researchOutput.appendChild(dateJournal);
    // on the next line, we want to put the title, and the altmetric badge the title should link to the research output
    // we'll use a flexbox container for this as well - the badge should be no more than e0 wide, and the title should take up the rest of the space
    // on clicking the title, it should open the research output in a new tab
    var titleBadge = document.createElement('div');
    titleBadge.style.display = 'flex';
    titleBadge.style.justifyContent = 'space-between';
    var title = document.createElement('h3');
    title.innerHTML = output.Title;

    // wrap the h3 in an a tag
    var titleLink = document.createElement('a');
    titleLink.href = output.URL;
    titleLink.target = '_blank';
    titleLink.appendChild(title);

    titleBadge.appendChild(titleLink);
    var altmetricBadge = document.createElement('img');
    altmetricBadge.src = output.badge;
    altmetricBadge.style.width = '50px';
    altmetricBadge.style.height = '50px';
    titleBadge.appendChild(altmetricBadge);
    // ok, now get the abstract, and the authors, and put them in a div, with the authors on top, and the abstract below
    var authorsAbstract = document.createElement('div');
    authorsAbstract.style.display = 'flex';
    authorsAbstract.style.flexDirection = 'column';
    var authors = document.createElement('p');
    authors.innerHTML = output.authors;
    authors.style.fontStyle = 'italic';
    // next get the abstract
    var abstract = document.createElement('p');
    abstract.innerHTML = output.Abstract;

    // append the authors and abstract to the authorsAbstract div

    authorsAbstract.appendChild(authors);
    authorsAbstract.appendChild(abstract);

    researchOutput.appendChild(titleBadge);
    researchOutput.appendChild(authorsAbstract);
    // ok, now, lets add some styling to reserachOutput, so that it visually looks like a piece of paper
    researchOutput.style.padding = '20px';
    researchOutput.style.marginBottom = '20px';
    researchOutput.style.border = '1px solid #000';
    researchOutput.style.backgroundColor = '#fff';
    researchOutput.style.boxShadow = '5px 5px 5px #ccc';
    researchOutput.style.borderRadius = '10px';
    // shape it to look like an academic paper with a bit of css
    researchOutput.style.position = 'relative';
    researchOutput.style.overflow = 'hidden';
    researchOutput.style.zIndex = '0';
    // give it a bit of random rotation to add to the effect
    researchOutput.style.transform = `rotate(${Math.random() * 2 - 1}deg)`;


    // it should have an a4 aspect ratio, but only be 80% of the width of the container
    researchOutput.style.width = '33%';
    // researchOutput.style.height = 'calc(200px * 1.414)';
    // it should have a page break after it

    researchOutput.style.pageBreakAfter = 'always';


    return researchOutput
}


function imageStack() {
    // ok, this function takes the images in the images.json file.
    // it should randomly select an image, and display it inside the imageStack div,
    // but, it should be randomly rotated and scaled, and placed on top of images that have already been placed.


    // find the div that will hold the images
    var imageStack = document.getElementById('imageStack');
    // get a random index from the length of images array
    var randomIndex = Math.floor(Math.random() * 25);
    // create an img tag
    var img = document.createElement('img');

    // make sure that the randomIndex is not the same as the lastindex
    while (randomIndex === lastindex) {
        randomIndex = Math.floor(Math.random() * 65);
    }
    // set the src attribute of the img tag to the image url
    img.src = `img/task_images/${images[randomIndex].name}`;
    // set the class of the img tag to "stacked"
    img.className = "stacked";
    // set the style of the img tag to a random rotation and scale
    // however, constrain the scale to be between 0.9 and 1, and the rotation to be between 0 and 15 degrees in either direction
    // use the variable laststackdir to determine if the image should be rotated clockwise or counter-clockwise
    img.style.transform = `rotate(${Math.random() * 5 * laststackdir}deg) scale(${0.9 + Math.random() * 0.1})`;
    // give it a blur filter, with an amount of 0, so that it is not blurred, but so that the filter can be animated later
    img.style.filter = `blur(0px)`;


    // change the laststackdir variable to be the opposite of what it was
    laststackdir *= -1;

    // give the image a little bit of padding and a light grey border
    // set the z-index of the img tag to the lastImage variable
    img.style.zIndex = lastz;
    // increment the lastImage variable
    lastz += 1;
    lastindex = randomIndex;

    // for all the images i the imagestack div, add a blur and transparency filter, which in increases as the image gets further down the stack
    for (let i = 0; i < imageStack.children.length; i++) {
        let percent_from_top = 1 - (i / imageStack.children.length);

        imageStack.children[i].style.filter = `blur(${percent_from_top * 5}px)`;
    }


    // append the img tag to the imageStack div
    imageStack.appendChild(img);
    // ensure that there are never more than stackDepth images in the imageStack div
    // howver, each image that is removed, should be faded out over the course of 5 seconds.
    // no css rules should be used to accomplish this.
    if (imageStack.children.length > stackDepth) {
        let firstImage = imageStack.children[0];
        firstImage.style.opacity = 0;
        setTimeout(() => {
            imageStack.removeChild(firstImage);
        }, 5000);
    }
}

let mediaDetailsClicked = false
let lastMediaDetails = null


function submitToAPI(e) {
    e.preventDefault();
    let URL = "https://2skdcm17bb.execute-api.eu-west-2.amazonaws.com/v1/send";

    let name = document.getElementById('Name').value;
    let email = document.getElementById('Email').value;
    let desc = document.getElementById('Message').value;

    // if the name, email, or desc are empty, return
    if (name === '' || email === '') {
        return
    }

    let data = {
        name: name, email: email, desc: desc
    };
    // now we want to send the data to the api - not using jquery
    let xhr = new XMLHttpRequest();
    xhr.open("POST", URL);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(data));
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                console.log('sent');
                document.getElementById('contactform').reset();
                document.getElementById('thanksdiv').style.display = 'block';
            } else {
                console.log('failed');
            }
        }
    }

}

document.getElementById('contactform').addEventListener('submit', submitToAPI);

window.addEventListener('load', (event) => {

    // initialize the map on the "map" div with a given center and zoom
    // var map = L.map('map').setView([51.505, -0.09], 2); // You can change the coordinates and zoom level
    //
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     maxZoom: 19, attribution: '© OpenStreetMap contributors'
    // }).addTo(map);
    //
    //
    // // pre-load the image stack to the depth of 5 images, by repeating it 5 times
    // for (let i = 0; i < stackDepth; i++) {
    //     imageStack();
    // }
    //
    // // run the imagestack function every 10 seconds
    // setInterval(imageStack, 10000);
    //
    // reserachOutputs()
    //
    // var markers = L.markerClusterGroup({spiderfyDistanceMultiplier: 3});
    //
    // for (const k in articles) {
    //
    //     var pub = articles[k];
    //
    //     var tmpicon = L.icon({
    //         iconUrl: pub.logo, iconSize: [100, null],
    //     });
    //
    //
    //     const lat = pub.latitude
    //     const lon = pub.longitude
    //     const logo = pub.logo
    //     const _articles = pub.articles
    //
    //     try {
    //         var marker = L.marker(new L.LatLng(lat, lon), {icon: tmpicon})
    //         // when the marker is clicked it should set the content of the mediaDetails div to the title of the article
    //
    //         marker.on('click', function (e) {
    //             var mediaDetails = document.getElementById('mediaDetails');
    //
    //             if (mediaDetailsClicked == true && lastMediaDetails == k) {
    //                 mediaDetailsClicked = false
    //                 lastMediaDetails = null
    //                 mediaDetails.innerHTML = '';
    //                 return
    //             } else {
    //                 mediaDetailsClicked = true
    //                 lastMediaDetails = k
    //             }
    //             // get the mediaDetails div
    //             // clear the mediaDetails div
    //             mediaDetails.innerHTML = '';
    //             // add the logo image to the mediaDetails div
    //
    //
    //             // add a container to hold the title, description, link, and date of the article
    //
    //             // loop through the articles and add them to the mediaDetails div
    //             _articles.forEach(function (article) {
    //                 // first create a container to hold the title, description, link, and date of the article
    //                 var articleContainer = document.createElement('div');
    //                 var img = document.createElement('img');
    //                 img.src = logo;
    //                 img.style.width = '100px';
    //                 articleContainer.appendChild(img);
    //                 articleContainer.className = 'articleContainer';
    //                 // add the date and title to the articleContainer
    //                 var date = document.createElement('p');
    //                 date.innerHTML = article.date;
    //                 articleContainer.appendChild(date);
    //                 var title = document.createElement('h3');
    //                 title.innerHTML = article.name;
    //                 articleContainer.appendChild(title);
    //
    //                 var link = document.createElement('a');
    //                 link.innerHTML = 'Read more';
    //                 link.href = article.link;
    //                 link.target = '_blank';
    //                 articleContainer.appendChild(link);
    //                 // add the articleContainer to the container
    //                 // format the articleContainer to be in keeping with the design of the site
    //                 articleContainer.style.padding = '20px';
    //                 articleContainer.style.marginBottom = '20px';
    //                 articleContainer.style.border = '1px solid #000';
    //                 articleContainer.style.backgroundColor = '#fff';
    //                 articleContainer.style.boxShadow = '5px 5px 5px #ccc';
    //                 articleContainer.style.borderRadius = '10px';
    //                 articleContainer.style.position = 'relative';
    //                 articleContainer.style.overflow = 'hidden';
    //                 articleContainer.style.zIndex = '0';
    //                 articleContainer.style.transform = `rotate(${Math.random() * 2 - 1}deg)`;
    //
    //                 mediaDetails.appendChild(articleContainer);
    //
    //             });
    //
    //
    //         })
    //
    //         markers.addLayer(marker);
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }
    // map.addLayer(markers); // Add the cluster group to the map

});

